import React from "react";

import SEO from "../components/SEO";
import HeroSection from "../components/partials/beyond-lp/HeroSection";
import HowToSection from "../components/partials/beyond-lp/HowToSection";
import heroImage from "@src/assets/images/beyond-lp/homeMonsters.png";

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Home Monsters on ClassDojo"
        description="ClassDojo brings your school together, wherever you are, free"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Home Monsters on ClassDojo",
          description: "ClassDojo brings your school together, wherever you are, free",
        }}
      />
      <HeroSection
        topTitleTranslation="directus.page_home_monsters.section_1_header"
        topSubtitleTranslation="directus.page_home_monsters.section_1_subheader"
        heroTitleTranslation="directus.page_home_monsters.section_2_header"
        heroTextTranslation="directus.page_home_monsters.section_2_text"
        heroImage={heroImage}
      />
      <HowToSection />
    </>
  );
};
export default IndexPage;
